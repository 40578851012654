import styled from 'styled-components';
import { rgba } from 'polished';
import StyledInput from '@components/elements/Input/StyledInput';

import style from '@constants/style';

import SelectArrow from '@assets/img/icons/SelectArrow.svg';

export const StyledSelect = styled(StyledInput)`
  width: 100%;
  padding-right: 2.25rem;
  position: relative;
  background-image: url(${SelectArrow});
  background-repeat: no-repeat;
  background-size: 1.5rem 1rem;
  background-position: right 0.5rem center;
  background-clip: border-box;

  color: ${(props) => (props.value ? style.colours.secondaryDarkGray1 : rgba(style.colours.secondaryDarkGray1, 0.5))};
`;

const Select = StyledSelect;

export default Select;
