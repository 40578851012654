import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Card from '@components/containers/Card';
import LoggedPage from '@components/containers/LoggedPage';
import FlexBox from '@components/containers/FlexBox';

import BankSelect from '@components/modules/BankSelect';

import Headline from '@components/elements/Headline';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';
import bankLoginActions from '@constants/bankLoginActions';

const ConnectBank = ({ accountId }) => {
  const userId = useSelector(({ user }) => user.id);
  const socialSecurityNumber = useSelector(({ user }) => user.socialSecurityNumber);
  const bank = useSelector(({ signup }) => signup.bank);

  return (
    <LoggedPage
      title="Anslut din bank"
      previousUrl={getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId })}
    >
      <Card>
        <FlexBox
          flexDirection="column"
          alignItems="flexStart"
          margin="regular"
        >
          <Headline
            fontWeight="bold"
            fontSize="tiny"
            as="h2"
            marginBottom="none"
            marginRight="small"
          >
            Koppla bankkonto till Moank
          </Headline>

          <BankSelect
            label="Välj din bank"
            customerId={userId}
            socialSecurityNumber={{ value: socialSecurityNumber, valid: true }}
            bank={bank}
            action={bankLoginActions.MANDATE}
            accountId={accountId}
            showBankDataPolicy={false}
          />
        </FlexBox>
      </Card>
    </LoggedPage>
  );
};

ConnectBank.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default ConnectBank;
