import styled from 'styled-components';
import { rgba, rem } from 'polished';
import style from '@constants/style';

import { StyledFlexBox } from '@components/containers/FlexBox';

import check from '@assets/img/icons/check-white.svg';

const BORDER_WIDTH = 0.0625;
const PADDING_WIDTH = 0.75;
const SPACE_BETWEEN = 2;

const getInputColor = (props) => {
  if (props.hasError) return style.colours.tertiaryFailure;
  return rgba(style.colours.secondaryDarkGray1, 0.2);
};

export const StyledRadio = styled(StyledFlexBox)`
  align-items: center;
  justify-content: left;

  outline: none;
  appearance: none;
  width: calc(50% - ${(2 * BORDER_WIDTH) + (2 * PADDING_WIDTH) + (SPACE_BETWEEN / 2)}rem);
  border: ${BORDER_WIDTH}rem solid ${getInputColor};
  color: ${style.colours.primaryDark};
  border-radius: 0.25rem;
  background-color: ${style.colours.secondaryLightGray1};
  margin: 0.25rem 0;
  padding: 0.75rem ${PADDING_WIDTH}rem;

  &:hover {
    cursor: pointer;
  }

  & > input[type="radio"] {
    appearance: none;
    outline: none;
    border: ${BORDER_WIDTH}rem solid ${rgba(style.colours.secondaryDarkGray1, 0.2)};
    border-radius: 50%;
    height: ${rem('22px')};
    margin: 0;
    margin-right: ${style.margin.small};
    padding: 0;
    width: ${rem('22px')};
    cursor: inherit;

    &:checked {
      background-color: ${({ theme }) => theme.primaryColour};

      &::after {
        background-image: url(${check});
        background-size: ${rem('14px')} ${rem('11px')};
        background-repeat: no-repeat;
        display: inline-block;
        width: ${rem('14px')};
        height: ${rem('11px')};
        content:"";
        position: relative;
        top: ${rem('5px')};
        left: ${rem('3px')};
      }
    }
  }

  background-color: ${({ checked, theme }) => (checked ? rgba(theme.primaryColour, 0.1) : '')};
  border-color: ${({ checked, theme }) => (checked ? rgba(theme.primaryColour, 0.1) : '')};

`;

const Radio = StyledRadio;

export default Radio;
