import styled from 'styled-components';
import { StyledFlexBox } from '@components/containers/FlexBox';

const StyledRadioGroup = styled(StyledFlexBox)`
  justify-content: space-between;
  flex-direction: ${({ children }) => (children.length > 2 ? 'column' : 'row')};
  width: 100%;
`;

export default StyledRadioGroup;
