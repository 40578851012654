import styled from 'styled-components';
import { rgba } from 'polished';
import style from '@constants/style';
import greenTick from '@assets/img/icons/check-green.svg';

const getInputColor = (props) => {
  if (props.hasError) return style.colours.tertiaryFailure;
  return rgba(style.colours.secondaryDarkGray1, 0.2);
};

const getStandardWidth = (props) => {
  if (props.inputWidth) return `${props.inputWidth}rem`;
  return '100%';
};

const VERTICAL_PADDING = 0.75;
const HORIZONTAL_PADDING = 0.75;
const GAP_SUFFIX = 0.5;
const SUFFIX_WIDTH = 1;
const BORDER_WIDTH = 0.0625;
const INPUT_VERTICAL_MARGIN = 0.25;
const LINE_HEIGHT = 1.2;
const FONT_SIZE = style.fontSize.regular;

const StyledInput = styled('input')`
  outline: none;
  appearance: none;
  border: ${BORDER_WIDTH}rem solid ${getInputColor};
  font-size: ${FONT_SIZE};
  font-family: ${style.fontFamily.main};
  line-height: ${LINE_HEIGHT};
  padding: ${VERTICAL_PADDING}rem ${HORIZONTAL_PADDING}rem;
  width: ${(props) => `calc(${getStandardWidth(props)} - ${2 * HORIZONTAL_PADDING + 2 * BORDER_WIDTH}rem)`};
  color: ${style.colours.secondaryDarkGray1};
  font-weight: ${style.fontWeight.regular};
  margin: ${INPUT_VERTICAL_MARGIN}rem 0;
  border-radius: 0.2rem;
  background-color: ${style.colours.secondaryLightGray1};
  text-align: ${({ textAlign }) => textAlign};

  ${(props) => props.isSuccess && `
    background-image: url(${greenTick});
    background-repeat: no-repeat;
    background-size: ${SUFFIX_WIDTH}rem auto;
    background-position: right ${HORIZONTAL_PADDING}rem top ${VERTICAL_PADDING + INPUT_VERTICAL_MARGIN + BORDER_WIDTH}rem;
    background-clip: border-box;
    padding-right: ${HORIZONTAL_PADDING + SUFFIX_WIDTH + GAP_SUFFIX}rem;
    width: calc(${getStandardWidth(props)} - ${2 * HORIZONTAL_PADDING + SUFFIX_WIDTH + GAP_SUFFIX + 2 * BORDER_WIDTH}rem);
  `}

  &:focus {
    box-shadow: ${style.inputShadow};
  }


  &::placeholder {
    color: ${rgba(style.colours.secondaryDarkGray1, 0.5)};
  }

  &[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export const StyledUnitToInput = styled('span')`
  position: relative;
  &::before {
    position: absolute;
    bottom: ${VERTICAL_PADDING + INPUT_VERTICAL_MARGIN + BORDER_WIDTH}rem;
    line-height: calc(${FONT_SIZE} * ${LINE_HEIGHT});
    right: ${HORIZONTAL_PADDING}rem;
    ${({ unit }) => (unit ? `content: "${unit}";` : '')}
    font-size: ${style.fontSize.regular};
    width: ${SUFFIX_WIDTH}rem;

    ${(props) => props.isSuccess && `
      right: ${SUFFIX_WIDTH + HORIZONTAL_PADDING + GAP_SUFFIX}rem;
    `}
  }


  ${StyledInput} {
    padding-right: ${SUFFIX_WIDTH + HORIZONTAL_PADDING + GAP_SUFFIX}rem;
    width: ${(props) => `calc(${getStandardWidth(props)} - ${SUFFIX_WIDTH + 2 * HORIZONTAL_PADDING + GAP_SUFFIX + 2 * BORDER_WIDTH}rem)`};

    ${(props) => props.isSuccess && `
      padding-right: ${2 * SUFFIX_WIDTH + HORIZONTAL_PADDING + 2 * GAP_SUFFIX}rem;
      width: calc(${getStandardWidth(props)} - ${2 * SUFFIX_WIDTH + 2 * HORIZONTAL_PADDING + 2 * GAP_SUFFIX + 2 * BORDER_WIDTH}rem);
    `}
  }
`;

export default StyledInput;
