import React from 'react';
import ReactQRCode from 'qrcode.react';
import PropTypes from 'prop-types';

import FlexBox from '@components/containers/FlexBox';

const QRCode = ({ link }) => (
  <FlexBox minWidth="100%" justifyContent="center" paddingTop="big" paddingBottom="big">
    <ReactQRCode
      value={link}
      size={256}
      renderAs="svg"
    />
  </FlexBox>
);

QRCode.propTypes = {
  link: PropTypes.string.isRequired,
};

export default QRCode;
