import styled from 'styled-components';
import style from '@constants/style';

const DEFAULT_START_PADDING = '2.5rem';
const NO_START_PADDING = '1.25rem';

const StyledUl = styled('ul')`
  margin: 0 0 ${style.margin.regular};
  padding-inline-start: ${({ noStartPadding }) => (noStartPadding
    ? NO_START_PADDING
    : DEFAULT_START_PADDING)};

  ${style.collapseMarginMixin()}
`;

const Ul = StyledUl;

export default Ul;
