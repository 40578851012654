import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

import Input from '@components/elements/Input';
import Paragraph from '@components/elements/Paragraph';
import Ul from '@components/elements/Ul';
import Li from '@components/elements/Li';
import InfoButton from '@components/elements/InfoButton';

import BankProviders from '@components/modules/BankProviders';

import helpTexts from '@constants/helpTexts';

import { updateField } from '@redux/actions/signup';
import ucBdiLogo from '@assets/img/icons/uc_bdi_logo.svg';
import { startBankLoginSession } from '@redux/actions/bank';
import bankLoginActions from '@constants/bankLoginActions';
import { cookiesNames, getCookie, setCookie } from '@utils/cookies';
import FlexBox from '@components/containers/FlexBox';
import Icon from '@components/elements/Icon';
import Button from '@components/elements/Button/Button';
import Loader from '@components/elements/Loader';

import config from '../../../configs';

const { ucBdiPercentage } = config;

const BankSelect = ({
  bank,
  banks,
  socialSecurityNumber,
  customerId,
  action,
  accountId,
  dispatchUpdateField,
  label,
  showBankDataPolicy,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const providers = useSelector((state) => state.settings.constants.providers);

  const onClickStartSession = useCallback((e) => {
    e.preventDefault();
    if (!socialSecurityNumber.valid) return;
    dispatch(startBankLoginSession(providers.UC, {
      socialSecurityNumber: socialSecurityNumber.value,
      accountId,
      action: bankLoginActions.MANDATE,
    }));
    setIsLoading(true);
  }, [socialSecurityNumber, dispatch, providers.UC, accountId]);

  const shouldDisplayUcProvider = useMemo(() => {
    const hasExperienceCookie = getCookie(cookiesNames.EXPERIENCE_COOKIES_ALLOWED);
    const newShouldDisplayUcProvider = getCookie(cookiesNames.SHOW_UC_PROVIDER) || (Math.random() * 100) < ucBdiPercentage;

    if (hasExperienceCookie && !getCookie(cookiesNames.SHOW_UC_PROVIDER)) {
      setCookie(cookiesNames.SHOW_UC_PROVIDER, newShouldDisplayUcProvider, 1);
    }
    return newShouldDisplayUcProvider;
  }, []);

  if (shouldDisplayUcProvider) {
    return (
      <FlexBox maxWidth="26rem" flexDirection="column" marginBottom="huge" alignSelf="center" marginTop="big">
        <Icon icon={ucBdiLogo} iconWidth="6rem" />
        <Paragraph textAlign="center" marginBottom="huge" marginTop="big">Vi samarbetar med våran partner UC för att hämta dina kontouppgifter och koppla autogiro.</Paragraph>
        {isLoading ? <Loader /> : <Button size="big" onClick={onClickStartSession}>Nästa steg</Button>}
      </FlexBox>
    );
  }

  return (
    <>
      <Input
        _id="bank"
        inputType="select"
        label={label}
        value={bank.value}
        onChange={dispatchUpdateField}
        values={banks}
        hasError={bank.valid === false}
        isSuccess={bank.valid}
        defaultOption="Välj din bank"
      />
      {bank.valid && (
        <BankProviders
          bank={bank}
          socialSecurityNumber={socialSecurityNumber}
          customerId={customerId}
          action={action}
          accountId={accountId}
        />
      )}
      {showBankDataPolicy && (
        <>
          <Paragraph>Vi samlar in nedanstående finansiell data för att ge dig bästa möjliga villkor:</Paragraph>
          <Ul>
            <Li>Bankkontouppgifter</Li>
            <Li>Identitetsinformation</Li>
            <Li>Transaktionshistorik</Li>
          </Ul>
          <Paragraph>Läs mer om vår datainsamling här <InfoButton title="Datainsamling gällande ansökningsprocess" helpText={helpTexts.BANK_DATA_AGGREGATION} /></Paragraph>
        </>
      )}
    </>
  );
};

BankSelect.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bank: PropTypes.shape({
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  socialSecurityNumber: PropTypes.shape({
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
  }).isRequired,
  dispatchUpdateField: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  customerId: PropTypes.string,
  showBankDataPolicy: PropTypes.bool,
};

BankSelect.defaultProps = {
  customerId: null,
  label: 'Välj din bank',
  showBankDataPolicy: false,
  accountId: undefined,
};

const mapStateToProps = ({ settings }) => ({
  banks: settings.choices.activeBanks,
});

const mapDispatchToProps = {
  dispatchUpdateField: updateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankSelect);
