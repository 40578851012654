import React from 'react';
import PropTypes from 'prop-types';

import TinkLink from '@components/modules/TinkLink';
import OpenPaymentsLink from '@components/modules/OpenPaymentsLink';

import { openPaymentsBanks } from '@constants/banks';

const BankProviders = ({
  socialSecurityNumber,
  customerId,
  bank,
  action,
  accountId,
}) => {
  if (openPaymentsBanks.includes(bank.value)) {
    return (
      <OpenPaymentsLink
        bank={bank.value}
        customerId={customerId}
        socialSecurityNumber={socialSecurityNumber.value}
        action={action}
        accountId={accountId}
      />
    );
  }

  return (
    <TinkLink
      bank={bank.value}
      socialSecurityNumber={socialSecurityNumber.value}
      customerId={customerId}
      action={action}
      accountId={accountId}
    />
  );
};

BankProviders.propTypes = {
  socialSecurityNumber: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  bank: PropTypes.shape({
    value: PropTypes.string,
    valid: PropTypes.bool,
  }).isRequired,
  accountId: PropTypes.string,
  action: PropTypes.string.isRequired,
  customerId: PropTypes.string,
};

BankProviders.defaultProps = {
  customerId: null,
  accountId: undefined,
};

export default BankProviders;
