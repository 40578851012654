import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InfoButton from '@components/elements/InfoButton';
import Checkbox from '@components/elements/Checkbox';
import Select from '@components/elements/Select';
import Radio from '@components/elements/Radio';

import StyledInput, { StyledUnitToInput } from '@components/elements/Input/StyledInput';
import StyledDefaultOption from '@components/elements/Input/StyledDefaultOption';
import StyledInputBlock from '@components/elements/Input/StyledInputBlock';
import StyledLabel from '@components/elements/Input/StyledLabel';
import StyledRadioGroup from '@components/elements/Input/StyledRadioGroup';

const noop = (value) => value;

const Input = React.forwardRef(({
  _id,
  inputType,
  values,
  children,
  info,
  infoTitle,
  type,
  defaultOption,
  value,
  disabled,
  hasError,
  isSuccess,
  placeholder,
  min,
  max,
  label,
  onChange,
  onBlur,
  parser,
  formatter,
  suffix,
  inline,
  textAlign,
  inputWidth,
}, ref) => {
  const [formattedValue, setFormattedValue] = useState(formatter(value));
  useEffect(() => {
    setFormattedValue((currentFormattedValue) => {
      if (formatter(value) !== formatter(parser(currentFormattedValue))) return formatter(value);
      return currentFormattedValue;
    });
  }, [value, formatter, parser]);

  const onChangeValue = useCallback((e) => {
    const inputValue = inputType === 'checkbox' ? e.target.checked : e.target.value;
    setFormattedValue(inputValue);
    const parsedValue = parser(inputValue);

    onChange(_id, parsedValue, { min, max });
  }, [_id, inputType, parser, onChange, min, max]);

  const onBlurInput = useCallback((e) => {
    if (!onBlur) return;

    const inputValue = inputType === 'checkbox' ? e.target.checked : e.target.value;
    const parsedValue = parser(inputValue);

    setFormattedValue(formatter(parsedValue));
    onBlur(_id, parsedValue, { min, max });
  }, [_id, inputType, parser, formatter, onBlur, min, max]);

  let input;
  let labelComponent = null;

  switch (inputType.toLocaleLowerCase()) {
    case 'select': {
      const options = values.map((obj) => (
        <option
          key={`key-${obj.value}-${obj.label}`}
          value={obj.value}
          disabled={obj.disabled}
        >
          {obj.label}
        </option>
      ));
      input = (
        <Select
          as="select"
          id={_id}
          ref={ref}
          onChange={onChangeValue}
          onBlur={onBlurInput}
          value={formattedValue || ''}
          disabled={disabled}
          hasError={hasError}
        >
          <StyledDefaultOption key="key" disabled={!!formattedValue}>{defaultOption}</StyledDefaultOption>
          {options}
        </Select>
      );
      break;
    }
    case 'checkbox':
      input = (
        <Checkbox
          hasError={hasError}
          isSuccess={isSuccess}
          checked={formattedValue}
        >
          <input
            id={_id}
            type="checkbox"
            name={_id}
            ref={ref}
            onChange={onChangeValue}
            onBlur={onBlurInput}
            checked={formattedValue}
            disabled={disabled}
          />
        </Checkbox>
      );
      labelComponent = children ? (
        <StyledLabel htmlFor={_id}>
          {children}
        </StyledLabel>
      ) : null;
      break;
    case 'radio':
      input = (
        <StyledRadioGroup ref={ref}>
          {values.map((item) => {
            const id = `${_id}-${item.id}`;
            return (
              <Radio
                as="label"
                key={item.id}
                checked={formattedValue === item.value}
                htmlFor={id}
              >
                <input
                  id={id}
                  type="radio"
                  name={_id}
                  value={item.value}
                  checked={formattedValue === item.value}
                  onChange={onChangeValue}
                  onBlur={onBlurInput}
                />
                {item.label}
              </Radio>
            );
          })}
        </StyledRadioGroup>
      );
      break;
    case 'input':
    default:
      input = (
        <StyledInput
          type={type}
          id={_id}
          ref={ref}
          placeholder={placeholder}
          onChange={onChangeValue}
          onBlur={onBlurInput}
          value={formattedValue}
          disabled={disabled}
          hasError={hasError}
          isSuccess={isSuccess}
          min={min && min.toString()}
          max={max && max.toString()}
          textAlign={textAlign}
          inputWidth={inputWidth}
        />
      );
  }

  if (label) {
    labelComponent = (
      <StyledLabel htmlFor={_id}>
        {label}
        {' '}
        {!!info && <InfoButton title={infoTitle || label} helpText={info} />}
      </StyledLabel>
    );
  }

  if (suffix) {
    input = (
      <StyledUnitToInput
        unit={suffix}
        isSuccess={isSuccess}
        inputWidth={inputWidth}
      >
        {input}
      </StyledUnitToInput>
    );
  }

  return (
    <StyledInputBlock horizontal={inputType === 'checkbox'} inline={inline}>
      {labelComponent}
      {input}
    </StyledInputBlock>
  );
});

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.bool,
  PropTypes.number,
]);

Input.propTypes = {
  _id: PropTypes.string,
  inputType: PropTypes.oneOf(['select', 'checkbox', 'input', 'radio']),
  hasError: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  infoTitle: PropTypes.string,
  value: valueType,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valueType,
    label: PropTypes.string,
    disabled: PropTypes.boolean,
  })),
  children: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultOption: PropTypes.string,
  parser: PropTypes.func,
  formatter: PropTypes.func,
  suffix: PropTypes.string,
  inline: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'right']),
  inputWidth: PropTypes.number,
};

Input.defaultProps = {
  _id: undefined,
  inputType: 'input',
  isSuccess: false,
  type: 'text',
  label: '',
  info: '',
  infoTitle: '',
  value: '',
  placeholder: '',
  disabled: false,
  values: [],
  children: null,
  min: undefined,
  max: undefined,
  defaultOption: 'Välj alternativ',
  parser: noop,
  formatter: noop,
  suffix: undefined,
  onBlur: undefined,
  inline: false,
  textAlign: 'left',
  inputWidth: undefined,
};

export default React.memo(Input);
