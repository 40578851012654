import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FlexBox from '@components/containers/FlexBox';

import Button from '@components/elements/Button';
import Loader from '@components/elements/Loader';

import { startBankLoginSession, retrieveBankData } from '@redux/actions/bank';
import { providers } from '@constants';
import bankLoginStatuses from '@constants/bankLoginStatuses';

const TinkLink = (props) => {
  const {
    socialSecurityNumber,
    bank,
    customerId,
    action,
    accountId,
    noBankPrefilled,
  } = props;

  const dispatch = useDispatch();
  const loginSession = useSelector((state) => state.bank.loginSession, shallowEqual);
  const reportId = useSelector((state) => state.bank.reportId);
  const startSessionLoading = useSelector((state) => state.bank.startSessionLoading);
  const retrieveDataLoading = useSelector((state) => state.bank.retrieveDataLoading);
  const {
    error,
    sessionId,
    token,
    status,
  } = loginSession;

  const fetchTinkLinkAndRedirect = useCallback((e) => {
    e.preventDefault();
    const parameters = {
      socialSecurityNumber,
      tinkReportType: 'risk-analysis',
      bank,
      customerId,
      action,
      accountId,
    };
    dispatch(startBankLoginSession(providers.TINK_LINK, parameters));
  }, [action, bank, customerId, dispatch, socialSecurityNumber, accountId]);

  const retryRetrieveData = useCallback((e) => {
    e.preventDefault();
    dispatch(retrieveBankData(providers.TINK_LINK, token));
  }, [dispatch, token]);

  const isValid = (socialSecurityNumber || customerId) && (bank || noBankPrefilled);
  const pendingTinkRedirection = !!sessionId && status === bankLoginStatuses.STARTED;

  return (
    <>
      {error && (
        <FlexBox alignItems="center" flexDirection="column" margin="regular">
          <Button
            disabled={!isValid || !!retrieveDataLoading}
            onClick={retryRetrieveData}
          >
            Hämta data
          </Button>
        </FlexBox>
      )}
      {!error && !retrieveDataLoading && !reportId && !pendingTinkRedirection && (
        <FlexBox alignItems="center" flexDirection="column" margin="regular">
          <Button
            disabled={!isValid || !!startSessionLoading}
            onClick={fetchTinkLinkAndRedirect}
          >
            Legitimera mig
          </Button>
        </FlexBox>
      )}
      {retrieveDataLoading && !startSessionLoading && (
        <Loader message="Vi hämtar dina bankuppgifter. Vänligen vänta." />
      )}
      {(startSessionLoading || pendingTinkRedirection) && (
        <Loader message="Ansluter till Tink. Vänligen vänta." />
      )}
    </>
  );
};

TinkLink.propTypes = {
  socialSecurityNumber: PropTypes.string,
  bank: PropTypes.string,
  customerId: PropTypes.string,
  action: PropTypes.string.isRequired,
  noBankPrefilled: PropTypes.bool,
  accountId: PropTypes.string,
};

TinkLink.defaultProps = {
  socialSecurityNumber: undefined,
  bank: undefined,
  customerId: undefined,
  noBankPrefilled: false,
  accountId: undefined,
};

export default TinkLink;
