import styled from 'styled-components';
import { rem, rgba } from 'polished';
import style from '@constants/style';

import { StyledFlexBox } from '@components/containers/FlexBox';

import check from '@assets/img/icons/check-white.svg';

const SIZE = rem('30px');
const INPUT_WIDTH = rem('20px');
const INPUT_HEIGHT = rem('15px');

const getInputColor = (props) => {
  if (props.hasError) return style.colours.tertiaryFailure;
  return rgba(style.colours.secondaryDarkGray1, 0.2);
};

export const StyledCheckbox = styled(StyledFlexBox)`
  margin-left: ${style.margin.regular};

  & > input[type="checkbox"] {
    appearance: none;
    outline: none;

    border: 0.0625rem solid ${getInputColor};
    border-radius: 0.1rem;
    background-color: ${style.colours.secondaryLightGray1};
    height: ${SIZE};
    width: ${SIZE};
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:disabled {
      background-color: ${style.colours.secondaryLightGray5};
      &:checked {
        background-color: ${style.colours.secondaryDarkGray4};
      }
      cursor: not-allowed;
    }

    &:checked {
      background-color: ${({ theme }) => theme.primaryColour};
      &::after {
        content:"";
        background-image: url(${check});
        background-size: ${INPUT_WIDTH} ${INPUT_HEIGHT};
        background-repeat: no-repeat;
        display: inline-block;
        width: ${INPUT_WIDTH};
        height: ${INPUT_HEIGHT};
        position: relative;
        top: ${rem('7px')};
        left: ${rem('4px')};
      }
    }

    &::-ms-check{
      display: none; /* Fix on IE11 to hide the default checkbox style */
    }
  }
`;

const Checkbox = StyledCheckbox;

export default Checkbox;
