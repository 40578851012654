import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { showModal } from '@redux/actions/ui';
import { startBankLoginSession, bankReset } from '@redux/actions/bank';

import { bankIdTypes, bankIdRequests } from '@constants/bankId';
import bankLoginStatuses from '@constants/bankLoginStatuses';
import modalTypes from '@components/modals/types';

import Loader from '@components/elements/Loader';
import Paragraph, { StyledParagraph } from '@components/elements/Paragraph';
import Anchor from '@components/elements/Anchor';
import QRCode from '@components/elements/QRCode';
import Button from '@components/elements/Button';
import Image from '@components/elements/Image';

import FlexBox from '@components/containers/FlexBox';

const QRCodeInstructionsWrapper = styled(FlexBox)`
  flex-direction: column;

  ${StyledParagraph} {
    margin: 0
  }
`;

const ActionLink = ({
  action,
  marginTop,
  title,
}) => (
  <FlexBox justifyContent="center" marginTop={marginTop}>
    <Anchor underlined onClick={action}>
      <Paragraph fontSize="smaller">{title}</Paragraph>
    </Anchor>
  </FlexBox>
);

ActionLink.propTypes = {
  action: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.string,
};

ActionLink.defaultProps = {
  marginTop: 'none',
};

const OpenPaymentsLink = ({
  bank,
  customerId,
  socialSecurityNumber,
  action,
  accountId,
}) => {
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.settings.constants.providers.OPEN_PAYMENTS);
  const {
    loginSession: {
      status: loginSessionStatus,
      sessionId,
    },
    scaApproach,
    image,
    link,
    startSessionLoading,
    retrieveDataLoading,
    bankAuthenticationLoading,
  } = useSelector((state) => state.bank, shallowEqual);

  const bankIdType = useSelector((state) => state.auth.bankIdType);
  const modal = useSelector((state) => state.ui.modal.type);

  const [showQrCode, setShowQrCode] = useState(false);

  const [isError] = useState(retrieveDataLoading);
  useEffect(() => {
    // If retrieveDataLoading is true on mounting component, something wrong happened
    if (isError) {
      dispatch(showModal({ type: modalTypes.ERROR }));
      dispatch(bankReset());
    }
  }, [dispatch, isError]);

  const onClickStartBankLoginSession = useCallback((e) => {
    e.preventDefault();

    dispatch(startBankLoginSession(provider, {
      bank,
      socialSecurityNumber,
      customerId,
      action,
      accountId,
    }));
  }, [dispatch, provider, bank, socialSecurityNumber,
    customerId, action, accountId]);

  const onClickShowQrCode = useCallback((e) => {
    e.preventDefault();

    setShowQrCode(true);
  }, []);

  const onClickRedirectLink = useCallback((e) => {
    e.preventDefault();

    if (!link) return;
    global.location = link;
  }, [link]);

  const onClickGetNewLink = useCallback((e) => {
    e.preventDefault();
    dispatch(showModal({
      type: modalTypes.BANK_ID,
      props: {
        request: bankIdRequests.BANK_AUTHENTICATION,
        payload: { sessionId },
      },
    }));
  }, [dispatch, sessionId]);

  const onClickRestart = useCallback((e) => {
    e.preventDefault();
    dispatch(bankReset());
  }, [dispatch]);

  if (loginSessionStatus === bankLoginStatuses.SUCCESS) return <Loader message="Vi hämtar dina bankuppgifter. Vänligen vänta." />;

  if (scaApproach === 'REDIRECT') {
    return (
      <>
        <Loader message="Omdirigering till din bank portal..." />
        <ActionLink action={onClickRedirectLink} title="Öppna länken manuellt" marginTop="regular" />
      </>
    );
  }

  if (scaApproach === 'DECOUPLED' && (!!link || !!image)) {
    if (bankIdType === bankIdTypes.SAME_DEVICE && !showQrCode) {
      return (
        <>
          <Loader message="Omdirigering till Mobilt BankId..." />
          <ActionLink action={onClickShowQrCode} title="Visa QR-kod" marginTop="regular" />
        </>
      );
    }

    if (loginSessionStatus === bankLoginStatuses.BANK_AUTHENTICATION_SUCCESS) {
      if (bankAuthenticationLoading) return <Loader message="Vänligen vänta." />;

      return (
        <>
          <Paragraph textAlign="center">Din bank är nu tillfälligt ansluten. Du behöver bekräfta anslutningen för att kunna försätta med din ansökan.</Paragraph>
          <FlexBox justifyContent="center" marginTop="regular">
            <Button onClick={onClickGetNewLink}>Bekräfta anslutningen</Button>
          </FlexBox>
        </>
      );
    }

    return (
      <>
        <QRCodeInstructionsWrapper>
          <Paragraph>1. Öppna Bank-ID appen på din mobil.</Paragraph>
          <Paragraph>2. I BankID-appen tryck på QR-symbolen.</Paragraph>
          <Paragraph>3. Rikta kameran mot QR-koden i denna ruta.</Paragraph>
        </QRCodeInstructionsWrapper>
        {link ? (
          <>
            <ActionLink action={onClickRedirectLink} marginTop="regular" title="Öppna med BankID-appen" />
            <QRCode link={link} />
          </>
        ) : (
          <>
            <ActionLink action={onClickRestart} marginTop="regular" title="Starta om" />
            <FlexBox minWidth="100%" justifyContent="center" paddingTop="big" paddingBottom="big">
              <Image src={image} height={256} weight={256} />
            </FlexBox>
          </>
        )}
      </>
    );
  }

  if (modal) return null;

  if (startSessionLoading || bankAuthenticationLoading) return <Loader message="Vänligen vänta." />;

  return (
    <FlexBox justifyContent="center" marginTop="regular">
      <Button onClick={onClickStartBankLoginSession}>Legitimera mig</Button>
    </FlexBox>
  );
};

OpenPaymentsLink.propTypes = {
  socialSecurityNumber: PropTypes.string,
  bank: PropTypes.string.isRequired,
  customerId: PropTypes.string,
  action: PropTypes.string,
  accountId: PropTypes.string,
};

OpenPaymentsLink.defaultProps = {
  socialSecurityNumber: undefined,
  customerId: undefined,
  action: undefined,
  accountId: undefined,
};

export default OpenPaymentsLink;
